import React, {useState} from 'react';

import DraggableContainerStyle from './DraggableContainer.module.css';

const DraggableContainer = ({ children, gridSize, className }) => {
  const [isDragging, drag] = useState(false);

  return (
    <div
      ref={drag}
      className={`${DraggableContainerStyle.draggableContainer} ${DraggableContainerStyle[`gridSize-${gridSize}`]} ${className}`}
    >
      {children}
    </div>
  );
};

export default DraggableContainer;
