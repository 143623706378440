import React from "react";
import BaseStyle from '../assets/css/Dashboard.module.css';
import CameraElement from '../components/CameraElement';
import {useTheme} from "../provider/themeProvider";
import {useAppContext} from "../provider/appProvider";
import {useDesktop} from "../provider/DesktopContext";

const Cameras = () => {
  const { darkMode } = useTheme();
  const { cameras } = useAppContext();
  const isDesktop = useDesktop();

  return (
    <>
      {isDesktop &&
        <>
          <div className={BaseStyle.header}>
            <div className={BaseStyle.left}>
              <div className={BaseStyle.iconContainer}>
                <img src={`https://cdn.codnum.fr/watch4safe/assets/images/camera_${darkMode ? 'black' : 'white'}.svg`} alt="" height="20" width="20"/>
              </div>
              <div className={BaseStyle.description}>
                <span className={BaseStyle.title}>Caméras</span>
                <span className={BaseStyle.subTitle}>Toutes vos caméras</span>
              </div>
            </div>
            <div className={BaseStyle.right}>
            </div>
          </div>
        </>
      }
      <div className={BaseStyle.contentContainer}>
        {cameras.map((camera, index) => {
          return (
            <CameraElement name={camera.name} link={camera.streamLink} gridSize={4} id={camera.id} key={camera.id}></CameraElement>
          );
        })}
      </div>
    </>
  );
};

export default Cameras;
