import React from "react";

import { useTheme } from '../../provider/themeProvider';

import PopupDownMenuStyle from './PopupDownMenu.module.css';
import {usePopup} from "../../services/PopupContext";
import PopupDownSystem from "./PopupDownSystem";
import PopupDownAccount from "./PopupDownAccount";

const PopupDownMenu = (props) => {
  const { index } = props;

  const { showPopup, hidePopup } = usePopup();
  const { darkMode } = useTheme();

  const handleClickSystem = () => {
    hidePopup(index);
    showPopup(PopupDownSystem);
  };

  const handleClickAccount = () => {
    hidePopup(index);
    showPopup(PopupDownAccount);
  };

  return (
    <div className={PopupDownMenuStyle.container}>
      <span className={PopupDownMenuStyle.title}>Menu</span>
      <div className={PopupDownMenuStyle.content}>
        <div className={PopupDownMenuStyle.nav}>
          <div className={PopupDownMenuStyle.navElement} onClick={handleClickSystem}>
            <img className={PopupDownMenuStyle.navImage} src={`https://cdn.codnum.fr/watch4safe/assets/images/setting_${darkMode ? 'black' : 'white'}.svg`} alt={''}></img>
            <span>Mon système</span>
          </div>

          <div className={PopupDownMenuStyle.navElement} onClick={handleClickAccount}>
            <img className={PopupDownMenuStyle.navImage} src={`https://cdn.codnum.fr/watch4safe/assets/images/user_${darkMode ? 'black' : 'white'}.svg`} alt={''}></img>
            <span>Mon compte</span>
          </div>
        </div>

        <div className={PopupDownMenuStyle.help}>
          <span className={PopupDownMenuStyle.subTitle}>Aide et assistance</span>
          <div className={PopupDownMenuStyle.helpContent}>
            <div className={PopupDownMenuStyle.helpElement}>
              <img height={16} width={16} src={`https://cdn.codnum.fr/watch4safe/assets/images/warning_${darkMode ? 'black' : 'white'}.svg`} alt={''}></img>
              <span>Signaler un problème</span>
            </div>
            <div className={PopupDownMenuStyle.helpElement}>
              <img height={16} width={16} src={`https://cdn.codnum.fr/watch4safe/assets/images/phone_${darkMode ? 'black' : 'white'}.svg`} alt={''}></img>
              <span>Nous contacter</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopupDownMenu;
