import React, { forwardRef } from "react";
import InputBase from './Input.module.css';

const Input = forwardRef((props, ref) => {
  const { type, name, required, label, value, onChange, onKeyUp = (() => {}) } = props;

  return (
    <div className={InputBase.form}>
      { label &&
        <label className={InputBase.label} htmlFor={`form-${name}`}>
          {label}{required && '*'}
        </label>
      }
      <input
        className={InputBase.input}
        type={type}
        name={`form-${name}`}
        id={`form-${name}`}
        required={required}
        value={value}
        onChange={onChange}
        onKeyUp={onKeyUp}
        ref={ref}
      />
    </div>
  );
});

export default Input;
