import React, { createContext, useState, useContext, useEffect } from 'react';
import api from "../services/api";

const AppStateContext = createContext();

export const useAppContext = () => useContext(AppStateContext);

export const AppProvider = ({ children }) => {
  const [notificationsDate, setNotificationsDate] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [countNotification, setCountNotification] = useState(0);
  const [systemsAvailable, setAvailableSystems] = useState([]);
  const [system, setSystem] = useState(null);
  const [cameras, setCameras] = useState([]);
  const [shortcuts, setShortcuts] = useState([]);
  const [timelapses, setTimelapses] = useState([]);

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const response = await api.get('/notifications/all');
        let notificationCounts = 0;

        response.data.data.forEach(item => {
          const unreadNotifications = item.notifications.filter(notification => notification.read_at === null);
          notificationCounts += unreadNotifications.length;
        });

        const allNotifications = response.data.data.flatMap(item => item.notifications);
        const sorted = allNotifications.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

        setNotifications(sorted);
        setNotificationsDate(response.data.data);
        setCountNotification(notificationCounts);
      } catch (error) {
        console.error("Failed to fetch notifications", error);
        setNotificationsDate([]);
        setNotifications([]);
        setCountNotification(0);
      }
    };

    fetchNotifications();
  }, []);

  useEffect(() => {
    const getShortCuts = async () => {
      try {
        const response = await api.get(`/client/shortcuts`);
        setShortcuts(response.data.shortCuts);
      } catch (error) {
        console.error(error);
        setShortcuts([]);
      }
    };

    getShortCuts();
  }, []);

  useEffect(() => {
    const getTimelapses = async () => {
      try {
        const response = await api.get(`/client/timelapses`);
        setTimelapses(response.data.data);
      } catch (error) {
        console.error(error);
        setTimelapses([]);
      }
    };

    getTimelapses();
  }, []);

  useEffect(() => {
    const getCurrentSystem = async () => {
      try {
        const response = await api.get(`/client/system`);
        setSystem(response.data.data);
      } catch (error) {
        console.error(error);
        setSystem(null);
      }
    };

    getCurrentSystem();
  }, []);

  useEffect(() => {
    const fetchCameras = async () => {
      try {
        const response = await api.get('/client/cameras');
        setCameras(response.data.cameras);
      } catch (error) {
        console.error("Failed to fetch cameras", error);
        setCameras([]);
      }
    };

    fetchCameras();
  }, []);

  useEffect(() => {
    const fetchSystems = async () => {
      try {
        const response = await api.get('/client/systems/all');
        const result = [];

        for(const theSystem of response.data.data) {
          result.push({
            image: 'https://cdn.codnum.fr/watch4safe/assets/images/favicon.svg',
            icon: false,
            title: theSystem.name,
            link: ``,
            id : theSystem.id,
          });
        }
        setAvailableSystems(result);
      } catch (error) {
        setAvailableSystems([]);
      }
    };

    fetchSystems();
  }, []);

  return (
    <AppStateContext.Provider value={{countNotification, setCountNotification, systemsAvailable, cameras, shortcuts, notificationsDate, notifications, setNotifications, system, setSystem, timelapses, setTimelapses}}>
      {children}
    </AppStateContext.Provider>
  );
};
