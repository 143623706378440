import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../provider/authProvider";

export const NotAuthenticatedRoute = () => {
  const { user } = useAuth();

  console.log(user);
  if (user) {
    console.log('interdit');
      return <Navigate to="/" />;
  }

  return <Outlet />;
};
