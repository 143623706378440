import React, {useEffect, useState} from "react";

import { useNavigate } from "react-router-dom";
import PopupDownNewSequenceLoadingStyle from './PopupDownNewSequenceLoading.module.css';
import { ReactComponent as LoaderIcon } from '../../assets/images/loader.svg';
import api from "../../services/api";
import {usePopup} from "../../services/PopupContext";
import {useAppContext} from "../../provider/appProvider";

const PopupDownNewSequenceLoading = (props) => {
  const { timelapseID, index } = props;
  const [isReady, setIsReady] = useState(false);
  const navigate = useNavigate();
  const { hidePopup } = usePopup()
  const { timelapses, setTimelapses } = useAppContext();

  const handleSeeSequence = (timelapseID) => {
    hidePopup(index);
    navigate(`/sequences/timelapse/${timelapseID}`, {replace: true});
  }

  const verifyIsReady = async (timelapseID) => {
    try {
      const response = await api.get(`/timelapse/${timelapseID}/data`);
      if (response.data.success) {
        console.log('response.data.success ' + response.data.success);
        setIsReady(response.data.data.ready);
        let updatedTimelapses = [...timelapses];
        updatedTimelapses.push(response.data.data);
        setTimelapses(updatedTimelapses);
      }
    } catch (error) {
      console.log((error.response && error.response.data) || error.message || error.toString());
      setIsReady(null);
    }
  };

  useEffect(() => {
    if (!isReady) {
      const intervalId = setInterval(() => {
        console.log(isReady);
        if (isReady !== null && !isReady) verifyIsReady(timelapseID);
      }, 1000);
      verifyIsReady(timelapseID);

      // Cleanup function to clear the interval when the component is unmounted
      return () => {
        clearInterval(intervalId);
        console.log("PopupDownNewSequenceLoading has been unmounted from the DOM.");
      };
    }
  }, [isReady]);

  return (
    <div className={PopupDownNewSequenceLoadingStyle.container}>
      <span className={PopupDownNewSequenceLoadingStyle.title}>{isReady ? 'Votre séquence est disponible' : 'Création de la séquence en cours'}</span>
      { isReady &&
        <input value={'Aller à la séquence'} type={"button"} className={PopupDownNewSequenceLoadingStyle.see}
               onClick={() => handleSeeSequence(timelapseID)}/>

      }
      {!isReady &&
        <LoaderIcon className={'loader'} height={50} width={50}/>
      }
    </div>
  );
};

export default PopupDownNewSequenceLoading;
