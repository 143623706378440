import AuthProvider from "./provider/authProvider";
import { ThemeProvider } from './provider/themeProvider';
import Routes from "./routes";
import {useEffect, useState} from "react";
import {DesktopProvider} from "./provider/DesktopContext";
import 'dayjs/locale/fr';

function App() {
  const [systemDarkMode, setSystemDarkMode] = useState(
    window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
  );

  useEffect(() => {
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    const handleChange = (e) => setSystemDarkMode(e.matches);

    mediaQuery.addEventListener('change', handleChange);

    return () => {
      mediaQuery.removeEventListener('change', handleChange);
    };
  }, []);


  return (
    <ThemeProvider defaultDarkMode={systemDarkMode}>
      <DesktopProvider>
        <AuthProvider>
          <Routes />
        </AuthProvider>
      </ DesktopProvider>
    </ThemeProvider>
  );
}

export default App;
