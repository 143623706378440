import React, {useEffect, useState} from "react";

import { useTheme } from '../../provider/themeProvider';

import PopupDownAccountStyle from './PopupDownAccount.module.css';
import {usePopup} from "../../services/PopupContext";
import { useNavigate } from "react-router-dom";
import {useAppContext} from "../../provider/appProvider";
import {getAuthData} from "../../provider/authProvider";
import UserImageProfile from "../UserImageProfile";

const PopupDownAccount = (props) => {
  const { index } = props;

  const [userName, setUserName] = useState(null);
  const [userRang, setUserRang] = useState(null);
  const authData = getAuthData();

  useEffect(() => {
    const getUser = async () => {
      try {
        setUserName(`${authData.firstName} ${authData.lastName}`);
        setUserRang('Administrateur' ? 'Administrateur' : 'Utilisateur');
      } catch (error) {

      }
    };

    getUser();
  }, []);

  const { showPopup, hidePopup } = usePopup();
  const { darkMode } = useTheme();
  const navigate = useNavigate();

  return (
    <div className={PopupDownAccountStyle.container}>
      <span className={PopupDownAccountStyle.title}>Votre compte</span>
      <div className={PopupDownAccountStyle.content}>
        <div className={PopupDownAccountStyle.account}>
          <div className={PopupDownAccountStyle.accountElement}>
            <UserImageProfile userID={authData.userID} name={userName} size={50}/>
            <div className={PopupDownAccountStyle.accountText}>
              <span className={PopupDownAccountStyle.accountTextName}>{userName}</span>
              <span className={PopupDownAccountStyle.accountTextRole}>{userRang}</span>
            </div>
          </div>
        </div>

        <div className={PopupDownAccountStyle.nav}>
          <div className={PopupDownAccountStyle.navContent}>
            <div className={PopupDownAccountStyle.navElement} onClick={() => {
              hidePopup(0);
              navigate('/account', {replace: true});
            }}>
              <img height={16} width={16} src={`https://cdn.codnum.fr/watch4safe/assets/images/account_${darkMode ? 'black' : 'white'}.svg`} alt={''}></img>
              <span>Mon compte</span>
            </div>
            <div className={PopupDownAccountStyle.navElement} onClick={() => {
              hidePopup(0);
              navigate('/logout', {replace: true});
            }}>
              <img height={16} width={16} src={`https://cdn.codnum.fr/watch4safe/assets/images/logout_${darkMode ? 'black' : 'white'}.svg`} alt={''}></img>
              <span>Me déconnecter</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopupDownAccount;
