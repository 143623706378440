import React, {useState, useEffect} from "react";
import { useParams } from 'react-router-dom';
import BaseStyle from '../assets/css/Dashboard.module.css';
import CameraElement from '../components/CameraElement';
import {useTheme} from "../provider/themeProvider";
import {useAppContext} from "../provider/appProvider";
import HomeStyle from "../assets/css/Home.module.css";
import NotificationElement from "../components/NotificationElement";
import DraggableContainer from "../components/DraggableContainer";
import PopupDownNewSequence from "../components/PopupDown/PopupDownNewSequence";
import {usePopup} from "../services/PopupContext";

const Camera = () => {
  const [camera, setCamera] = useState(null);
  const [notificationsCamera, setNotificationsCamera] = useState([]);
  const { id } = useParams();
  const { darkMode } = useTheme();
  const { showPopup } = usePopup();
  const { cameras, notifications } = useAppContext();

  useEffect(() => {
    setCamera(cameras.find((camera) => camera.id === id));
  }, [cameras]);

  useEffect(() => {
    setNotificationsCamera(notifications.filter((notification) => notification.camera_id === id).slice(0, 5));
  }, [notifications]);



  return (
    <>
      <div className={BaseStyle.header}>
        <div className={BaseStyle.left}>
          <div className={BaseStyle.iconContainer}>
            <img src={`https://cdn.codnum.fr/watch4safe/assets/images/camera_${darkMode ? 'black' : 'white'}.svg`} alt=""
height="20" width="20"/>

          </div>
          <div className={BaseStyle.description}>
            <span className={BaseStyle.title}>Caméra</span>
            <span className={BaseStyle.subTitle}>{camera && camera.name}</span>
          </div>
        </div>
        <div className={BaseStyle.right}>
          <div
            className={BaseStyle.btnAction}
            onClick={(event) => showPopup(PopupDownNewSequence, {cameraSelect: id})}
          >
            <img src={`https://cdn.codnum.fr/watch4safe/assets/images/plus_${darkMode ? 'black' : 'white'}.svg`}
                 alt={''} height={14} width={14}>
            </img>
          </div>
        </div>
      </div>
      <div className={BaseStyle.contentContainer}>
        {camera &&
          <CameraElement name={camera.name} link={camera.streamLink} gridSize={8} id={camera.id}
                         redirect={false}></CameraElement>
        }

        { notificationsCamera.length > 0 &&
          <DraggableContainer className={HomeStyle.notifications} gridSize={12}>
            <span className={HomeStyle.subTitle}>Dernières notifications</span>
            <div className={HomeStyle.notificationsContainer}>
              {notificationsCamera.map((notification, index) => {
                return (
                  <NotificationElement
                    key={index}
                    callback={notification.callback}
                    readed={notification.read_at}
                    type={notification.type}
                    title={notification.title}
                    description={notification.description}
                    calledBy={notification.called_by}
                    createdAt={new Date(notification.created_at)}
                    thumbnail={notification.thumbnail}
                    fullDate={true}
                  />
                )
              })}
            </div>
          </DraggableContainer>
        }
      </div>
    </>
  );
};

export default Camera;
