import React, { useEffect, useState } from "react";
import MediaPlayerStyle from '../assets/css/MediaPlayer.module.css';

const MediaPlayer = (props) => {
  const { pictures } = props;
  const [indexTarget, setIndexTarget] = useState(0);
  const [pictureTarget, setPictureTarget] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    pictures.forEach((picture) => {
      const img = new Image();
      img.src = picture;
    });
  }, []);

  useEffect(() => {
    let intervalId;
    if (isPlaying) {
      intervalId = setInterval(() => {
        setIndexTarget(prevIndex => prevIndex + 1);
      }, 100);
    }

    return () => clearInterval(intervalId);
  }, [isPlaying]);

  useEffect(() => {
    if (pictures && pictures.length > 0) {
      if (indexTarget >= pictures.length) {
        setIsPlaying(false);
      } else {
        setPictureTarget(pictures[indexTarget]);
      }
    }
  }, [indexTarget, pictures]);

  const togglePlay = () => {
    if (indexTarget === pictures.length) {
      setIndexTarget(0);
    }
    setIsPlaying(prevState => !prevState);
  };

  const handleChangeSlider = (event) => {
    setIndexTarget(Number.parseInt(event.target.value));
  };

  return (
    <div className={MediaPlayerStyle.element}>
      { pictures.length === 0 ?
        <>
          <span className={MediaPlayerStyle.emptyImages}>Aucune image disponible</span>
        </>
        :
        <>
          <div className={MediaPlayerStyle.picture}>
            <img className={MediaPlayerStyle.pictureImg} src={pictureTarget} alt=""/>
          </div>

          <div className={MediaPlayerStyle.controls}>
            <div className={MediaPlayerStyle.rangeSlider}>
              <input type="range" min="0" max={pictures.length} value={indexTarget} className={MediaPlayerStyle.slider}
                     onChange={handleChangeSlider}/>
            </div>
          </div>
          {isPlaying ? (
            <button className={'btn-01'} onClick={togglePlay}>Stop</button>
          ) : (
            <button className={'btn-01'} onClick={togglePlay}>Play</button>
          )}
        </>
      }


    </div>
  );
};

export default MediaPlayer;
