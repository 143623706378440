import React from "react";
import { useTheme } from '../../provider/themeProvider';
import HeaderStyle from './Header.module.css';
import {useAppContext} from "../../provider/appProvider";
import {NavLink} from "react-router-dom";
import {usePopup} from "../../services/PopupContext";
import PopupDownShortcut from "../PopupDown/PopupDownShortcut";
import PopupDownNewSequence from "../PopupDown/PopupDownNewSequence";

const Header = (props) => {
  const { darkMode } = useTheme();
  const { countNotification } = useAppContext();
  const { showPopup } = usePopup();

  return (
    <header className={HeaderStyle.header}>
      <img className={HeaderStyle.logo} src={`https://cdn.codnum.fr/watch4safe/assets/images/logo_${darkMode ? 'black' : 'white'}.svg`} alt={'Logo'}></img>
      <div className={HeaderStyle.actions}>
        <div
          className={HeaderStyle.btnAction}
          onClick={(event) => showPopup(PopupDownNewSequence)}
        >
          <img src={`https://cdn.codnum.fr/watch4safe/assets/images/plus_${darkMode ? 'black' : 'white'}.svg`} alt={''}
               height={14} width={14}></img>
        </div>

        <div
          className={HeaderStyle.btnAction}
          onClick={(event) => showPopup(PopupDownShortcut)}
        >
          <img src={`https://cdn.codnum.fr/watch4safe/assets/images/stack_${darkMode ? 'black' : 'white'}.svg`} alt={''}
               height={16} width={16}></img>
        </div>
        <NavLink
          className={`${HeaderStyle.btnAction} ${HeaderStyle.notifications}`}
          to={'/notifications'}>
          <img src={`https://cdn.codnum.fr/watch4safe/assets/images/notification_${darkMode ? 'black' : 'white'}.svg`} alt={''}
               height={16} width={16}></img>
          {countNotification > 0 &&
            (
              <div className={HeaderStyle.notificationsCount}>
                <span>{countNotification > 20 ? '+20' : countNotification}</span>
              </div>
            )
          }
        </NavLink>
      </div>
    </header>
  );
};

export default Header;
