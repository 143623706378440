import React, {useState, useRef, useEffect} from "react";

import BaseStyle from '../assets/css/Dashboard.module.css';
import HomeStyle from '../assets/css/Home.module.css';

import { useTheme } from "../provider/themeProvider";
import { useAppContext } from "../provider/appProvider";
import DraggableContainer from "../components/DraggableContainer";
import { ReactComponent as LoaderIcon } from '../assets/images/loader.svg';
import ShortcutElement from "../components/Shortcut/ShortcutElement";
import NotificationElement from "../components/NotificationElement";
import {usePopup} from "../services/PopupContext";
import PopupDownAlarm from "../components/PopupDown/PopupDownAlarm";
import CameraElement from "../components/CameraElement";
import {useDesktop} from "../provider/DesktopContext";

const Dashboard = () => {
  const { darkMode } = useTheme();
  const { cameras, shortcuts, notifications, system } = useAppContext();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [imagesLoaded, setImagesLoaded] = useState(Array(cameras.length).fill(false));
  const carouselRef = useRef(null);
  const isDesktop = useDesktop();

  const { showPopup } = usePopup();

  const handleScroll = (e) => {
    const lengthElement = carouselRef.current.scrollWidth / cameras.length;
    const newIndex = Math.round(carouselRef.current.scrollLeft / lengthElement);
    setCurrentIndex(newIndex);
  };

  const handleLoadCamera = (e, index) => {
    setImagesLoaded((prevLoaded) => {
      const newLoaded = [...prevLoaded];
      newLoaded[index] = true;
      return newLoaded;
    });
    carouselRef.current.style.scrollSnapType = 'x mandatory';
  };

  const handleLoadErrorCamera = (e, index) => {
    if (e.target.src !== '') {
      const camera = cameras[index];
      const dataset = e.currentTarget.dataset
      const retryNumber = Number(dataset.retry || '0')
      setTimeout(() => {
        e.target.src = '';
        e.target.src = camera.streamLink;
      }, retryNumber === 0 ? 100 : 5000);
      dataset.retry = (retryNumber + 1).toString();
    }
  };

  useEffect(() => {
    setCurrentIndex(0);
    console.log('call')
  }, [cameras]);

  const handlePointClick = (index) => {
    carouselRef.current.scrollTo({
      left: carouselRef.current.clientWidth * index,
      behavior: 'smooth'
    });
  };

  return (
    <>
      {isDesktop &&
        <>
          <div className={BaseStyle.header}>
            <div className={BaseStyle.left}>
              <div className={BaseStyle.iconContainer}>
                <img src={`https://cdn.codnum.fr/watch4safe/assets/images/home_${darkMode ? 'black' : 'white'}.svg`}
                     alt="" height="20" width="20"/>
              </div>
              <div className={BaseStyle.description}>
                <span className={BaseStyle.title}>Tableau de bord</span>
              </div>
            </div>
            <div className={BaseStyle.right}>
            </div>
          </div>
        </>
      }
        <>
          <div className={BaseStyle.contentContainer}>
            <div className={HomeStyle.alarm} onClick={(event) => showPopup(PopupDownAlarm)}>
              <img src={`https://cdn.codnum.fr/watch4safe/assets/images/favicon${(system && system.alarm_enabled) ? '_active' : ''}_${darkMode ? 'black' : 'white'}.svg`} alt={''} height={40}
                   width={40}></img>
              <div className={HomeStyle.alarmText}>
                <span className={HomeStyle.alarmTitle}>Système de détection</span>
                <span className={HomeStyle.alarmSubTitle}>{system && (system.alarm_enabled ? 'Activé' : 'Désactivé')} {system && system.alarm_enabled && (('- ') + system.alarms.find((alarm) => alarm.id === system.alarm_level_id).name)}</span>
              </div>
            </div>
            <DraggableContainer className={HomeStyle.cameras} gridSize={isDesktop ? 12 : 6}>
              <span className={HomeStyle.subTitle}>Caméras</span>

              {isDesktop &&
                <>
                  <div className={HomeStyle.camerasContainerDesktop}>
                    {cameras.map((camera, index) => {
                      return (
                        <CameraElement name={camera.name} link={camera.streamLink} gridSize={3} id={camera.id} key={camera.id}></CameraElement>
                      );
                    })}
                  </div>
                </>
              }
              {!isDesktop &&
                <>
                  <div className={HomeStyle.camerasContainer}>
                    <div className={HomeStyle.camerasContent} ref={carouselRef} onScroll={handleScroll}>
                      {cameras.map((camera, index) => (
                        <div className={HomeStyle.cameraChild} key={index}>
                          {!imagesLoaded[index] && (
                            <LoaderIcon className={'loader'} height={40} width={40}/>
                          )}
                          <img
                            className={`${HomeStyle.camera} ${!imagesLoaded[index] ? HomeStyle.hidden : ''}`}
                            src={camera.streamLink}
                            alt={`Camera ${camera.name}`}
                            onLoad={(e) => handleLoadCamera(e, index)}
                            onError={(e) => handleLoadErrorCamera(e, index)}
                            onAbort={(e) => console.log(e, index)}
                          />
                        </div>
                      ))}
                    </div>
                    <div className={HomeStyle.cameraName}>
              <span className={HomeStyle.cameraNameSpan}>
                {(cameras.find((camera, index) => index === currentIndex) || {name: ''}).name}
              </span>
                    </div>
                    <div className={HomeStyle.camerasPoints}>
                      {cameras.map((_, index) => (
                        <span
                          key={index}
                          className={`${HomeStyle.point} ${index === currentIndex ? HomeStyle.active : ''}`}
                          onClick={() => handlePointClick(index)}
                        ></span>
                      ))}
                    </div>
                  </div>
                </>
              }
            </DraggableContainer>
            <DraggableContainer className={HomeStyle.shortcuts} gridSize={isDesktop ? 12 : 6}>
              <span className={HomeStyle.subTitle}>Raccourcis</span>
              <div className={HomeStyle.shortcutsContainer}>
                {shortcuts && shortcuts.map((shortcut, index) => {
                  return (
                    <ShortcutElement
                      key={index}
                      name={shortcut.name}
                      icon={shortcut.icon}
                      iconActive={shortcut.icon_active}
                      title={shortcut.title}
                      titleActive={shortcut.title_active}
                      infosURLTag={shortcut.infos_short_url_tag}
                      URLTag={shortcut.short_url_tag}
                      activeURTag={shortcut.active_short_url_tag}
                      type={1}
                    />
                  )
                })}
              </div>
            </DraggableContainer>
            {/*<DraggableContainer className={HomeStyle.indicators} gridSize={6}>*/}
            {/*  <span className={HomeStyle.subTitle}>Indicateurs</span>*/}
            {/*  <div className={HomeStyle.indicatorsContainer}>*/}
            {/*    <div className={HomeStyle.indicatorsElement}>*/}

            {/*    </div>*/}
            {/*  </div>*/}
            {/*</DraggableContainer>*/}
            <DraggableContainer className={HomeStyle.notifications} gridSize={isDesktop ? 12 : 6}>
              <span className={HomeStyle.subTitle}>Dernières notifications</span>
              <div className={HomeStyle.notificationsContainer}>
                {notifications.slice(0, 5).map((notification, index) => {
                  return(
                    <NotificationElement
                      key={index}
                      callback={notification.callback}
                      readed={notification.read_at}
                      type={notification.type}
                      title={notification.title}
                      description={notification.description}
                      calledBy={notification.called_by}
                      createdAt={new Date(notification.created_at)}
                      thumbnail={notification.thumbnail}
                      fullDate={true}
                    />
                  )
                })}
              </div>
            </DraggableContainer>
          </div>
        </>

    </>
  );
};

export default Dashboard;
