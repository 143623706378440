import React from "react";

import ButtonBase from './Button.module.css';

const Input = (props) => {
  const { type, name, disabled } = props;

  return (
    <button 
      className={ButtonBase.button}
      type={type}
      disabled={disabled} >
      {name}
    </button>
  );
};

export default Input;
