import React, {useState} from "react";
import { NavLink } from "react-router-dom";

import { useTheme } from '../../provider/themeProvider';

import NavBarElementStyle from './NavBarElement.module.css';

const NavBarElement = (props) => {
  const { link, name, icon, count=null, children=null } = props;

  const [opened, setOpened] = useState(false);

  const { darkMode } = useTheme();

  const toggleMenu = (e) => {
    setOpened(!opened);
    e.preventDefault();
  }

  return (
    <li className={NavBarElementStyle.navElement}>
      <NavLink
        to={link}
        className={({ isActive, isPending, isTransitioning }) =>
          [
            isPending ? NavBarElementStyle.pending : "",
            isActive ? NavBarElementStyle.active : "",
            isTransitioning ? NavBarElementStyle.transitioning : "",
            NavBarElementStyle.navLink,
          ].join(" ")
        }
        end={children ? undefined : true}
        onClick={(event) => children && toggleMenu(event)
      }>
        <div className={NavBarElementStyle.left}>
          <img height={20} width={20} src={icon} alt={'Illustration'}/>
          <span className={NavBarElementStyle.name}>{name}</span>
        </div>

        <div className={NavBarElementStyle.right}>
          {(count !== null && count > 0) &&
            <div className={NavBarElementStyle.count}>
              <span className={NavBarElementStyle.countText}>{count > 20 ? '+20' : count}</span>
            </div>
          }

          {children &&
            <img className={`${NavBarElementStyle.arrow} ${opened ? NavBarElementStyle.opened : ''}`} alt={'Ouvrir'} src={`https://cdn.codnum.fr/watch4safe/assets/images/arrow_${darkMode ? 'black' : 'white'}.svg`}/>
          }
        </div>

      </NavLink>

      {children &&
        <div className={`${NavBarElementStyle.children} ${opened ? NavBarElementStyle.opened : ''}`}>
          <div className={NavBarElementStyle.threadContainer}>
            <div className={NavBarElementStyle.thread}></div>
          </div>
          <div className={NavBarElementStyle.childrenContainer}>
          {children.map((element, index) => {
            return (
              <NavLink
                end
                key={index}
                to={element.link}
                className={({ isActive, isPending, isTransitioning }) =>
                [
                  isPending ? NavBarElementStyle.pending : "",
                  isActive ? NavBarElementStyle.active : "",
                  isTransitioning ? NavBarElementStyle.transitioning : "",
                  NavBarElementStyle.childlink,
                ].join(" ")
              }
              >
                <span>{element.name}</span>
              </NavLink>
            );
          })}
          </div>
        </div>
      }
    </li>
  );
};

export default NavBarElement;
