import React, { useEffect, useRef, useState } from 'react';
import PopupDownStyle from '../../assets/css/PopupDown.module.css';
import { usePopup } from "../../services/PopupContext";

const PopupDown = ({ component: Component, props, index, canBeHidden= true }) => {
  const { hidePopup } = usePopup();
  const backgroundElementRef = useRef(null);
  const elementRef = useRef(null);
  const elementCloseRef = useRef(null);
  const [isHiding, setIsHiding] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [startY, setStartY] = useState(0);
  const [currentY, setCurrentY] = useState(0);

  const handleHidePopup = () => {
    if (!canBeHidden) {
      return;
    }
    setIsHiding(true);
    elementRef.current.style.transition = 'transform 0.2s ease';
    setTimeout(() => {
      hidePopup(index);
    }, 0); // 300
  };

  const handleMouseDown = (event) => {
    if (!canBeHidden) {
      return;
    }
    setIsDragging(true);
    setStartY(event.clientY || event.touches[0].clientY);
    setCurrentY(event.clientY || event.touches[0].clientY);
  };

  const handleMouseMove = (event) => {
    if (!isDragging) return;
    if((event.clientY || event.touches[0].clientY) < startY) {
      setCurrentY(startY);
      const deltaY = 0;
      elementRef.current.style.transform = `translateY(${deltaY}px)`;
      return;
    }
    setCurrentY(event.clientY || event.touches[0].clientY);
    const deltaY = (event.clientY || event.touches[0].clientY) - startY;
    elementRef.current.style.transform = `translateY(${deltaY}px)`;
  };

  const handleMouseUp = (event) => {
    if (!canBeHidden) {
      return;
    }
    if (event.target === elementCloseRef.current || elementCloseRef.current.contains(event.target) || event.target === backgroundElementRef.current) return;
    setIsDragging(false);
    const deltaY = currentY - startY;
    if (deltaY > 100) {
      handleHidePopup();
    } else {
      elementRef.current.style.transform = 'translateY(0)';
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (backgroundElementRef.current && backgroundElementRef.current === event.target) {
        handleHidePopup();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
    document.addEventListener("touchmove", handleMouseMove);
    document.addEventListener("touchend", handleMouseUp);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
      document.removeEventListener("touchmove", handleMouseMove);
      document.removeEventListener("touchend", handleMouseUp);
    };
  }, [backgroundElementRef, isDragging, startY, currentY]);

  return (
    <div className={PopupDownStyle.background} ref={backgroundElementRef}>
      <div className={`${PopupDownStyle.element} ${isHiding ? PopupDownStyle.hide : ''}`} ref={elementRef}>
        <div className={PopupDownStyle.head}
            onTouchStart={handleMouseDown} onMouseDown={handleMouseDown}>
          <div className={PopupDownStyle.line}></div>
        </div>
        <div className={PopupDownStyle.close} onClick={handleHidePopup} ref={elementCloseRef}>
          <img className={PopupDownStyle.closeIcon} src={`https://cdn.codnum.fr/watch4safe/assets/images/plus_white.svg`} alt={''} height={15} width={15} />
        </div>
        <Component {...props} index={index} />
      </div>
    </div>
  );
};

export default PopupDown;
