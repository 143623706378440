import React, {useEffect, useState} from "react";

import ShortcutElementStyle from './ShortcutElement.module.css';
import '../../index.css'
import api from "../../services/api";
import { ReactComponent as LoaderIcon } from '../../assets/images/loader.svg';
import {useTheme} from "../../provider/themeProvider";

const ShortcutElement = (props) => {
  const { name, icon, iconActive, title, titleActive, infosURLTag, URLTag, activeURTag, type = 0 } = props;
  const [isActive, setActive] = useState(null);

  const { darkMode } = useTheme();

  const verifyIsActive = async (urlInfosTag) => {
    try {
      const response = await api.get(`/shortURL/${urlInfosTag}`);
      setActive(Boolean(response.data.data));
    } catch (error) {
      console.log((error.response && error.response.data) || error.message || error.toString());
    }
  };

  useEffect(() => {
    if (infosURLTag) {
      const intervalId = setInterval(() => {
        verifyIsActive(infosURLTag);
      }, 5000);
      verifyIsActive(infosURLTag);

      return () => {
        clearInterval(intervalId);
        console.log("ShortcutElement has been unmounted from the DOM.");
      };
    }
  }, [infosURLTag]);

  const handleCLickShortcut = async (event) => {
    if(!URLTag || isActive === null) {
      return;
    }
    if (isActive) {
      const response = await api.get(`/shortURL/${activeURTag}`);
      console.log(response.data);
    } else {
      const response = await api.get(`/shortURL/${URLTag}`);
      console.log(response.data);
    }
    // addToast({id: Math.floor(Math.random() * (9999999999 - 1000000000) + 1000000000), type: 'information', title: 'Salut la commande est bien lancé', description: null, content: 'https://dev.stream.w4s.codnum.fr/stream/3F934E517F78943B07CE96EC40352F50'});
    verifyIsActive(infosURLTag);
  }

  return (
    <>
      { type === 0 && (
        <div className={ShortcutElementStyle.element} onClick={handleCLickShortcut}>
          {isActive === null && (
            <>
              <LoaderIcon className={'loader'} alt={name} height={20} width={20}/>
              <div className={ShortcutElementStyle.nameContainer}>
                <span className={ShortcutElementStyle.name}>Chargement...</span>
              </div>
            </>
          )}

          {isActive !== null && (
            <>
              <img
                src={`https://cdn.codnum.fr/watch4safe/assets/images/${isActive ? iconActive : icon}_${darkMode ? 'black' : 'white'}.svg`}
                alt={name} height={20}
                width={20}/>
              <div className={ShortcutElementStyle.nameContainer}>
                <span className={ShortcutElementStyle.name}>{isActive ? titleActive : title}</span>
              </div>
            </>
          )}
        </div>
      )}

      {type === 1 && (
        <div className={ShortcutElementStyle.element02} onClick={handleCLickShortcut}>
          {isActive === null && (
            <>
              <LoaderIcon className={'loader'} alt={name} height={40} width={40}/>
              <span className={ShortcutElementStyle.name02}>{name}</span>
            </>
          )}

          {isActive !== null && (
            <>
              <img
                src={`https://cdn.codnum.fr/watch4safe/assets/images/${isActive ? iconActive : icon}_${darkMode ? 'black' : 'white'}.svg`}
                alt={name} height={40} width={40}/>
              <span className={ShortcutElementStyle.name02}>{isActive ? titleActive : title}</span>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default ShortcutElement;
