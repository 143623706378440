import { createContext, useContext, useEffect, useMemo, useState } from "react";

const AuthContext = createContext();


const getLocalRefreshToken = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  return user?.refreshToken;
}

const getAuthData = () => {
  const accessToken = getLocalAccessToken();

  const data = accessToken.split('.')[1];

  var donneesUTF8 = new TextDecoder('utf-8').decode(Uint8Array.from(atob(data), c => c.charCodeAt(0)));
  console.log(donneesUTF8);
  return JSON.parse(donneesUTF8);
}

const getLocalAccessToken = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  return user?.accessToken;
}

const updateLocalAccessToken = (token) => {
  let user = JSON.parse(localStorage.getItem("user"));
  user.accessToken = token;
  localStorage.setItem("user", JSON.stringify(user));
}

const getUser = () => {
  return JSON.parse(localStorage.getItem("user"));
}

const removeUser = () => {
  localStorage.removeItem("user");
}

const AuthProvider = ({ children }) => {
  // State to hold the authentication token
  const [user, setUser_] = useState(JSON.parse(localStorage.getItem("user")));

  // Function to set the authentication token
  const setUser = (newToken) => {
    if (!newToken) {
      localStorage.removeItem("user");
    }
    setUser_(newToken);
  };

  useEffect(() => {
    if (user) {
      localStorage.setItem("user", JSON.stringify(user));
    } else {
      localStorage.removeItem("user");
    }
  }, [user]);

  // Memoized value of the authentication context
  const contextValue = useMemo(
    () => ({
      user,
      setUser,
    }),
    [user]
  );

  // Provide the authentication context to the children components
  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};

export default AuthProvider;

export {getLocalRefreshToken, getLocalAccessToken, updateLocalAccessToken, getUser, removeUser, getAuthData}
