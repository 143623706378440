import React, {useEffect, useState} from "react";

import { useTheme } from '../../provider/themeProvider';

import PopupDownSystemStyle from './PopupDownSystem.module.css';
import {getAuthData, useAuth} from "../../provider/authProvider";
import {useAppContext} from "../../provider/appProvider";
import api from "../../services/api";
import TokenService from "../../services/token.service";

const PopupDownSystem = (props) => {

  const { darkMode } = useTheme();
  const [currentSystem, setCurrentSystem] = useState(null);
  const authData = getAuthData();
  const { systemsAvailable } = useAppContext();
  const {setUser} = useAuth();

  const handleClickOtherSystem = async (systemID) => {
    try {
      const response = await api.post(`/client/systems/change/${systemID}`, {refreshToken: TokenService.getLocalRefreshToken()});

      await setUser(response.data);
      window.location.reload();
    } catch (error) {
      console.log('error');
    }
  };

  useEffect(() => {
    const getSystem = async () => {
      try {
        setCurrentSystem({
          id: authData.systemID,
          name: authData.systemName,
        })
      } catch (error) {
        setCurrentSystem({});
      }
    };

    getSystem();
  }, []);

  return (
    <div className={PopupDownSystemStyle.container}>
      <span className={PopupDownSystemStyle.title}>Mon Système</span>
      <div className={PopupDownSystemStyle.content}>
        <div className={PopupDownSystemStyle.system}>
          <div className={PopupDownSystemStyle.left}>
            <img src={`https://cdn.codnum.fr/watch4safe/assets/images/favicon_active_${darkMode ? 'black' : 'white'}.svg`} alt={'Alarme'}
                 height={30} width={30}/>
            <span className={PopupDownSystemStyle.nameSystem}>{currentSystem && currentSystem.name}</span>
          </div>
          <div className={PopupDownSystemStyle.checkbox}>
            <img
              src={`https://cdn.codnum.fr/watch4safe/assets/images/check_${darkMode ? 'black' : 'white'}.svg`}
              alt={'Alarme'}
              height={22} width={22}/>
          </div>
        </div>
        <div className={PopupDownSystemStyle.others}>
          <span className={PopupDownSystemStyle.subTitle}>Autres systèmes</span>

          <div className={PopupDownSystemStyle.contentOthers}>
            {systemsAvailable.map((otherSystem, index) => {
              return (
                <>
                  <div className={PopupDownSystemStyle.otherSystem}
                       onClick={() => handleClickOtherSystem(otherSystem.id)}>
                    <div className={PopupDownSystemStyle.left}>
                      <img
                        src={`https://cdn.codnum.fr/watch4safe/assets/images/favicon_active_${darkMode ? 'black' : 'white'}.svg`}
                        alt={'Alarme'}
                        height={30} width={30}/>
                      <span className={PopupDownSystemStyle.nameOtherSystem}>{otherSystem && otherSystem.title}</span>
                    </div>
                    <div className={PopupDownSystemStyle.checkbox}></div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopupDownSystem;
