import React from "react";

import ToastBarStyle from './ToastBar.module.css';
import ToastElement from "./ToastElement";
import {useToast} from "../../services/ToastContext";

const ToastBar = (props) => {
  const { toasts, removeToast } = useToast();

  return (
    <div className={ToastBarStyle.toastBar}>
      {toasts.map((toast, index) => (
        <ToastElement
          key={toast.id}
          onDelete={() => removeToast(toast.id)}
          {...toast}
        />
      ))}
    </div>
  );
};

export default ToastBar;
