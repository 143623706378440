import { Outlet } from "react-router-dom";

/* Import styles */
import BaseStyle from '../assets/css/Authentication.module.css';

import { useTheme } from '../provider/themeProvider';

export const AuthenticationRoute = () => {
  const { darkMode } = useTheme();
  return <>
    <div className={BaseStyle.authentication}>
      <div className={BaseStyle.logo_bg}>
        <img className={BaseStyle.logo} src={`https://cdn.codnum.fr/watch4safe/assets/images/logo_${darkMode ? 'black' : 'white'}.svg`}
             alt="Logo watch4safe"/>
      </div>

      <div className={BaseStyle.modal}>
        <Outlet/>

      </div>

      <div className={BaseStyle.version}>
        <span>v{process.env.REACT_APP_VERSION}</span>
      </div>
    </div>
  </>;
};

