import BaseStyle from '../assets/css/Dashboard.module.css';

import {useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import api from "../services/api";
import MediaPlayer from "../components/MediaPlayer";
import {useTheme} from "../provider/themeProvider";
import ButtonActionElement from "../components/ButtonAction.component";
import {useAppContext} from "../provider/appProvider";
import {usePopup} from "../services/PopupContext";
import PopupDownRename from "../components/PopupDown/PopupDownRename";
import PopupDownYesNo from "../components/PopupDown/PopupDownYesNo";
import PopupDownShare from "../components/PopupDown/PopupDownShare";
import PopupDownOldShare from "../components/PopupDown/PopupDownOldShare";
import {useDesktop} from "../provider/DesktopContext";

const Timelapse = () => {
  const { id } = useParams();
  const { darkMode } = useTheme();
  const navigate = useNavigate();
  const [sequence, setSequence] = useState(null);
  const [images, setImages] = useState([]);
  const { timelapses, setTimelapses } = useAppContext();
  const isDesktop = useDesktop();
  const { showPopup } = usePopup();

  useEffect(() => {
    setSequence(timelapses.find((element) => element.id === id));

    const getFiles = async () => {
      try {
        const response = await api.get(`/timelapse/${id}`);

        if (!response.data || !response.data.success) {
          throw new Error('Sequence not found');
        }

        setImages(response.data.files);
      } catch (error) {
        navigate('/sequences', { replace: true });
      }
    }

    getFiles();
  }, [timelapses]);

  const handleClickAction = async (action, data = null) => {
    try {
      const response = await api.put(`/client/timelapse/${sequence.id}`, {action, data});
      if (response.data.success) {
        if (response.data.data === null) {
          let updatedTimelapses = [...timelapses];
          updatedTimelapses = timelapses.filter((element) => element.id !== id);
          setTimelapses(updatedTimelapses);
          return null;
        }

        const updatedTimelapses = [...timelapses];
        const element = timelapses.find((element) => element.id === id);
        element.favorite = response.data.data.favorite;
        element.title = response.data.data.title;
        element.sharing_id = response.data.data.sharing_id;
        element.sharing = response.data.sharing || response.data.data.sharing;
        setTimelapses(updatedTimelapses);

        if (response.data.link !== null) {
          return response.data.link;
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleRename = () => {
    showPopup(PopupDownRename, { title: 'Veuillez choisir le nouveau nom', callback: (title) => handleClickAction('rename', {title}), defaultTitle: sequence.title });
  };

  const handleDelete = () => {
    showPopup(PopupDownYesNo, { request: 'Voulez-vous supprimer cette séquence ?', titleYes: 'Supprimer', titleNo: 'Annuler', callbackYes: () => handleClickAction('delete'), callbackNo: () => {} });
  };

  const handleShare = () => {
    if (sequence.sharing) {
      shareOldSequence();
    } else {
      shareSequence();
    }
  };

  const shareSequence = () => {
    showPopup(PopupDownShare, {
      title: 'Partager cette séquence',
      callbackShare: (time, password) => {
        handleClickAction('createShare', {time, password}).then((shareLink) => {
          if (shareLink) {
            shareOldSequence();
          }
        })
      }
    });
  }

  const shareOldSequence = () => {
    showPopup(PopupDownOldShare, {
      title: 'Votre partage',
      linkCopy: `${window.location.origin}/sharing/sequences/timelapse/${sequence.sharing_id}`,
      oldShare: sequence.sharing,
      callbackDelete: () => {
        handleClickAction('deleteShare');
      },
      callbackDeleteAndNew: (time, password) => {
        handleClickAction('deleteShare');
        shareSequence();
      }
    });
  }

  const handleDownload = () => {
    // TODO
  };

  return (
    <>
      <div className={BaseStyle.header}>
        <div className={BaseStyle.left}>
          {isDesktop &&
            <>
              <div className={BaseStyle.iconContainer}>
                <img src={`https://cdn.codnum.fr/watch4safe/assets/images/folder_${darkMode ? 'black' : 'white'}.svg`}
                     alt=""
                     height="20" width="20"/>
              </div>
              <div className={BaseStyle.description}>
                <span className={BaseStyle.title}>{sequence && sequence.title}</span>
              </div>
            </>
          }
        </div>
        <div className={BaseStyle.right}>
          <ButtonActionElement callback={handleDelete} size={20} icon={'trash'}/>
          {/*<ButtonActionElement callback={handleDownload} size={20} icon={'download'}/>*/}
          <ButtonActionElement callback={handleShare} size={20} icon={'share'}/>
          <ButtonActionElement callback={handleRename} size={20} icon={'rename'}/>
          <ButtonActionElement callback={() => handleClickAction('favorite')} size={20} icon={`star${sequence && sequence.favorite ? '_selected' : ''}`} />
        </div>
      </div>
      <div className={BaseStyle.contentContainer01}>
        {!isDesktop &&
          <span className={BaseStyle.title}>{sequence && sequence.title}</span>
        }
        {images &&
          <>
            <MediaPlayer pictures={images}/>
          </>
        }
      </div>
    </>
  );
};

export default Timelapse;
