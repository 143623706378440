import React, {createContext, useContext, useEffect, useState} from 'react';
import api from "./api";
import {getAuthData} from "../provider/authProvider";
import {useDesktop} from "../provider/DesktopContext";

const ToastContext = createContext();

export const ToastProvider = ({ children }) => {
  const [toasts, setToasts] = useState([]);

  const isDesktop = useDesktop();

  const addToast = (toast) => {
    if (!isDesktop) {
      setToasts([]);
    }
    setToasts((prevToasts) => [...prevToasts, toast]);
  };

  const removeToast = (id) => {
    setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
  };

  const authData = getAuthData();

  const verifyHasNotification = async () => {
    if (!authData) {
      return;
    }
    try {
      const response = await api.get(`/notifications/toast`);
      for (const notification of response.data.data) {
        addToast({id: notification.id, type: notification.type, title: notification.title, description: notification.description, content: notification.content, time: isDesktop ? 15 : 6, createdAt: new Date(notification.created_at), callback: notification.callback_toast});
      }
    } catch (error) {
    }
  };

  useEffect(() => {
    setInterval(() => {
      verifyHasNotification();
    }, 1000);
    verifyHasNotification();

  }, []);

  return (
    <ToastContext.Provider value={{ toasts, addToast, removeToast }}>
      {children}
    </ToastContext.Provider>
  );
};

export const useToast = () => {
  return useContext(ToastContext);
};
