import React, { createContext, useContext } from 'react';
import { useMediaQuery } from 'react-responsive';
import {createTheme, ThemeProvider} from "@mui/material/styles";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {useTheme} from "./themeProvider";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

const DesktopContext = createContext();

export const useDesktop = () => useContext(DesktopContext);

export const DesktopProvider = ({ children }) => {
  const isDesktop = useMediaQuery({ query: '(min-width: 1224px)' });
  const { darkMode } = useTheme();

  const theme = createTheme({
    palette: {
      mode: darkMode ? 'dark' : 'light',
    },
  });

  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.locale('fr');

  return (
    <DesktopContext.Provider value={isDesktop}>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr" theme={theme}>
          {children}
        </ LocalizationProvider>
      </ThemeProvider>
    </DesktopContext.Provider>
  );
};
