import BaseStyle from "../assets/css/Dashboard.module.css";
import React from "react";
import {useTheme} from "../provider/themeProvider";

const ButtonActionElement = (props) => {
  const { callback, size, icon } = props;

  const { darkMode } = useTheme();

  return (
    <div
      className={BaseStyle.btnAction}
      onClick={callback}>

      <img
        src={`https://cdn.codnum.fr/watch4safe/assets/images/${icon}_${darkMode ? 'black' : 'white'}.svg`}
        alt={''}
        height={size} width={size}></img>
    </div>
  );

};

export default ButtonActionElement;
