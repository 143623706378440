import React, {useEffect, useRef, useState} from "react";
import PopupDownRenameStyle from './PopupDownRename.module.css';
import PopupDownStyle from '../../assets/css/PopupDown.module.css';
import {usePopup} from "../../services/PopupContext";
import Input from "../Form/Input.component";

const PopupDownRename = (props) => {
  const { title, callback, defaultTitle } = props;
  const [inputValue, setInputValue] = useState(defaultTitle);
  const { hidePopup } = usePopup();
  const inputRef = useRef(null);

  const handleChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleKeyUpInput = (event) => {
    if (event.key === 'Enter') {
      hidePopup(0);
      callback(inputValue);
    }
  };

  useEffect(() => {
    inputRef.current.focus();
    inputRef.current.select();
  }, [])

  return (
    <div className={PopupDownStyle.container}>
      <span className={PopupDownStyle.title}>{title}</span>
      <div className={PopupDownStyle.content}>
        <div className={PopupDownRenameStyle.container}>
          <Input
            type={'text'}
            name={'text'}
            required={true}
            label={'Nom'}
            value={inputValue}
            onChange={handleChange}
            onKeyUp={handleKeyUpInput}
            ref={inputRef}
          />
          <div className={PopupDownRenameStyle.buttons}>
            <input
              value={'Annuler'}
              type="button"
              className={'btn-02'}
              disabled={false}
              onClick={() => hidePopup(0)}
            />

            <input
              value={'Renommer'}
              type="button"
              className={'btn-01'}
              disabled={false}
              onClick={() => {
                hidePopup(0);
                callback(inputValue);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopupDownRename;
