import React from "react";
import PopupDownYesNoStyle from './PopupDownYesNo.module.css';
import PopupDownStyle from '../../assets/css/PopupDown.module.css';
import {usePopup} from "../../services/PopupContext";

const PopupDownYesNo = (props) => {
  const { request, callbackYes, callbackNo, titleYes, titleNo } = props;
  const { hidePopup } = usePopup();

  return (
    <div className={PopupDownStyle.container}>
      <div className={PopupDownStyle.content}>
        <div className={PopupDownYesNoStyle.container}>
          <span className={PopupDownYesNoStyle.request}>{request}</span>
          <div className={PopupDownYesNoStyle.buttons}>
            <input
              value={titleNo}
              type="button"
              className={'btn-02'}
              disabled={false}
              onClick={() => {
                hidePopup(0);
                callbackNo();
              }}
            />

            <input
              value={titleYes}
              type="button"
              className={'btn-01'}
              disabled={false}
              onClick={() => {
                hidePopup(0);
                callbackYes();
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopupDownYesNo;
