import React, {useState} from "react";
import { useTheme } from '../../provider/themeProvider';
import PopupDownSecureSequenceStyle from './PopupDownSecureSequence.module.css';
import PopupDownStyle from '../../assets/css/PopupDown.module.css';
import Input from "../Form/Input.component";

const PopupDownSecureSequence = (props) => {
  const { title, callback, error, thePassword='' } = props;

  const { darkMode } = useTheme();
  const [password, setPassword] = useState(thePassword);

  const handleKeyUpInput = (event) => {
    if (event.key === 'Enter') {
      callback(password);
    }
  };

  const handleChangePassword = (event) => {
    setPassword(event.target.value);
  };

  return (
    <div className={PopupDownStyle.container}>
      <span className={PopupDownStyle.title}>{title}</span>
      <div className={PopupDownStyle.content}>
        <div className={PopupDownSecureSequenceStyle.container}>
          <div className={PopupDownSecureSequenceStyle.logo_bg}>
            <img className={PopupDownSecureSequenceStyle.logo}
                 src={`https://cdn.codnum.fr/watch4safe/assets/images/logo_${darkMode ? 'black' : 'white'}.svg`}
                 alt="Logo watch4safe"/>
          </div>
          <div className={PopupDownSecureSequenceStyle.inputs}>
            <Input
              type={'password'}
              name={'password'}
              required={false}
              label={'Mot de passe'}
              value={password}
              onChange={handleChangePassword}
              onKeyUp={handleKeyUpInput}
            />
            {error &&
              <span className={PopupDownSecureSequenceStyle.error}>Mot de passe incorrect</span>
            }
          </div>
          <div className={PopupDownSecureSequenceStyle.buttons}>
            <input
              value={'Valider'}
              type="button"
              className={'btn-01'}
              disabled={password === ''}
              onClick={() => {
                callback(password);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopupDownSecureSequence;
