import React from "react";
import { NavLink } from "react-router-dom";

import { useTheme } from '../../provider/themeProvider';

import NavigationElementStyle from './NavigationElement.module.css';

const NavigationElement = (props) => {
  const { link, icon, title, size} = props;

  const { darkMode } = useTheme();

  return (
    <NavLink
      to={link}
      className={({isActive, isPending, isTransitioning}) =>
        [
          isPending ? NavigationElementStyle.pending : "",
          isActive ? NavigationElementStyle.active : "",
          isTransitioning ? NavigationElementStyle.transitioning : "",
          NavigationElementStyle.navigationElement,
        ].join(" ")
      }
      end={true}
      onClick={(event) => (console.log('click'))
      }>
      {({ isActive }) => (
        <>
          <img
            className={NavigationElementStyle.icon}
            src={`https://cdn.codnum.fr/watch4safe/assets/images/${icon}${isActive ? '_selected' : ''}_${darkMode ? 'black' : 'white'}.svg`}
            alt={icon}
            height={size}
            width={size}
          />
          <span className={NavigationElementStyle.title}>{title}</span>
        </>
      )}
    </NavLink>
  );
};

export default NavigationElement;
