import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import CameraElementStyle from './CameraElement.module.css';
import DraggableContainer from './DraggableContainer';
import { ReactComponent as LoaderIcon } from '../assets/images/loader.svg';

const CameraElement = (props) => {
  const { link, name, gridSize = 6, id, redirect = true, type = 0, selected = false } = props;
  const [isLoaded, setIsLoaded] = useState(false);

  const navigate = useNavigate();

  const handleClickCamera = () => {
    if (type === 1) return;
    if (redirect) {
      navigate(`/cameras/${id}`, { replace: true });
    }
  };

  const handleLoadCamera = (e) => {
    setIsLoaded(true);
  };

  const handleLoadErrorCamera = (e) => {
    if (e.target.src !== '') {
      const dataset = e.currentTarget.dataset
      const retryNumber = Number(dataset.retry || '0')
      setTimeout(() => {
        e.target.src = '';
        e.target.src = link;
      }, retryNumber === 0 ? 100 : 5000);
      dataset.retry = (retryNumber + 1).toString();
    }
  };

  return (
    <DraggableContainer gridSize={gridSize} className={`${selected ? CameraElementStyle.selected : ''}`}>
      <div className={CameraElementStyle.cameraContainer} onClick={handleClickCamera}>
        {type === 0 && <div className={CameraElementStyle.header}>
          <span>{name}</span>
        </div>}
        <div className={CameraElementStyle.content}>
          <div className={CameraElementStyle.imageContainer}>
            {!isLoaded && (
              <LoaderIcon className={'loader'} height={40} width={40} />
            )}
            <img
              className={`${CameraElementStyle.image} ${!isLoaded ? CameraElementStyle.hidden : ''} ${type === 1 ? CameraElementStyle.small : ''}`}
              src={link}
              alt="Camera"
              onLoad={handleLoadCamera}
              onError={handleLoadErrorCamera}
            />
          </div>
        </div>
        {type === 1 && <div className={CameraElementStyle.footer}>
          <span>{name}</span>
        </div>}
      </div>
    </DraggableContainer>
  );
};

export default CameraElement;
