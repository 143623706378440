import { Outlet } from "react-router-dom";

/* Import styles */
import BaseStyle from '../assets/css/Dashboard.module.css';

/* Import images */

/* Import components */
import NavBar from '../components/NavBar/NavBar';
import Shortcut from '../components/Shortcut/Shortcut';
import ToastBar from "../components/Toast/ToastBar";
import {ToastProvider} from "../services/ToastContext";
import Header from "../components/Header/Header";
import NavigationBar from "../components/NavigationBar/NavigationBar";
import {useDesktop} from "../provider/DesktopContext";

export const DashboardRoute = () => {
  const isDesktop = useDesktop();

  return <>
    <ToastProvider>
      <div className={BaseStyle.dashboard}>
        {isDesktop && <NavBar />}
        {!isDesktop && <Header />}
        <div className={BaseStyle.dashboardContainer}>
          <div className={BaseStyle.content}>
            <Outlet />
          </div>
        </div>
        {!isDesktop && <NavigationBar />}
        {isDesktop && <Shortcut />}
      </div>
      <ToastBar/>
    </ToastProvider>
  </>;
};
