import React, {useEffect, useState} from "react";
import {usePopup} from "../services/PopupContext";
import PopupDownSecureSequence from "../components/PopupDown/PopupDownSecureSequence";
import {useTheme} from "../provider/themeProvider";
import PublicTimelapseStyle from "../assets/css/PublicTimelapse.module.css";
import api from "../services/api";
import MediaPlayer from "../components/MediaPlayer";
import {useParams} from "react-router-dom";

const PublicTimelapse = () => {
  const { sequenceType, id } = useParams();
  const { showPopup, hidePopup } = usePopup();
  const [sequence, setSequence] = useState(null);
  const [images, setImages] = useState([]);

  const { darkMode } = useTheme();

  const getTimelapse = async (thePassword) => {
    try {
      const response = await api.post(`/public/sequences/${sequenceType}/${id}`, {password: thePassword});
      console.log(response.data);
      if (!response.data.success) {
        return;
      }

      if (response.data.needPassword) {
        hidePopup(0);
        showPopup(PopupDownSecureSequence, {
          title: 'Séquence sécurisé',
          callback: (password) => {
            getTimelapse(password);
          }
        }, false);
        return;
      }

      if (response.data.invalidPassword) {
        hidePopup(0);
        showPopup(PopupDownSecureSequence, {
          title: 'Séquence sécurisé',
          error: true,
          thePassword: thePassword,
          callback: (password) => {
            getTimelapse(password);
          }
        }, false);
        return;
      }

      hidePopup(0);
      setSequence(response.data.timelapse);
      setImages(response.data.files);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getTimelapse(null);
  }, []);

  useEffect(() => {

  }, []);

  return (
    <>
      <div className={PublicTimelapseStyle.container}>
        <div className={PublicTimelapseStyle.logo_bg}>
          <img className={PublicTimelapseStyle.logo}
               src={`https://cdn.codnum.fr/watch4safe/assets/images/logo_${darkMode ? 'black' : 'white'}.svg`}
               alt="Logo watch4safe"/>
        </div>
        {images && images.length > 0 &&
          <>
            <MediaPlayer pictures={images}/>
          </>
        }
      </div>
    </>
  );
};

export default PublicTimelapse;
