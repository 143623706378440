import React, {useState, useEffect} from "react";
import NavBarElement from './NavBarElement'

import { getAuthData } from "../../provider/authProvider";
import { useTheme } from '../../provider/themeProvider';

import SelectorElement from '../NavBar/SelectorElement';

import NavBarStyle from './NavBar.module.css';
import {useAppContext} from "../../provider/appProvider";
import BaseStyle from "../../assets/css/Authentication.module.css";

const NavBar = (props) => {
  const [userName, setUserName] = useState(null);
  const [currentSystem, setCurrentSystem] = useState(null);
  const [userRang, setUserRang] = useState(null);

  const { countNotification, systemsAvailable } = useAppContext();

  const authData = getAuthData();

  const { darkMode } = useTheme();

  useEffect(() => {
    const getUser = async () => {
      try {
        setUserName(`${authData.firstName} ${authData.lastName}`);
        setUserRang('Administrateur' ? 'Administrateur' : 'Utilisateur');
      } catch (error) {

      }
    };

    const getSystem = async () => {
      try {
        setCurrentSystem({
          id: authData.systemID,
          name: authData.systemName,
        })
      } catch (error) {
      }
    };

    getSystem();
    getUser();
  }, []);

  return (
    <nav className={NavBarStyle.nav}>
      <SelectorElement elementSelected={{
        image: 'https://cdn.codnum.fr/watch4safe/assets/images/favicon.svg',
        title: (currentSystem && currentSystem.name)
      }} elements={systemsAvailable} position={0}/>
      <ul className={NavBarStyle.links}>
        <NavBarElement link={'/'} name={'Tableau de bord'}
                       icon={`https://cdn.codnum.fr/watch4safe/assets/images/home_${darkMode ? 'black' : 'white'}.svg`}/>
        <NavBarElement link={'/notifications'} name={'Notifications'}
                       icon={`https://cdn.codnum.fr/watch4safe/assets/images/notification_${darkMode ? 'black' : 'white'}.svg`}
                       count={countNotification}/>
        <NavBarElement link={'/system'} name={'Système'}
                       icon={`https://cdn.codnum.fr/watch4safe/assets/images/setting_${darkMode ? 'black' : 'white'}.svg`}
                       children={[{name: 'Mon système', link: '/system'}, {
                         name: 'Paramètres',
                         link: '/system/settings'
                       }, {name: 'Abonnement', link: '/system/subscription'}, {
                         name: 'Utilisateurs',
                         link: '/system/users'
                       }]}/>
        <NavBarElement link={'/cameras'} name={'Caméras'}
                       icon={`https://cdn.codnum.fr/watch4safe/assets/images/camera_${darkMode ? 'black' : 'white'}.svg`}/>
        <NavBarElement link={'/sequences'} name={'Séquences'}
                       icon={`https://cdn.codnum.fr/watch4safe/assets/images/folder_${darkMode ? 'black' : 'white'}.svg`}/>
      </ul>

      <div className={NavBarStyle.footer}>
        <SelectorElement elementSelected={{image: null, title: userName, subTitle: userRang}} elements={[{
          image: `https://cdn.codnum.fr/watch4safe/assets/images/account_${darkMode ? 'black' : 'white'}.svg`,
          icon: false,
          title: 'Mon compte',
          link: '/account'
        }, {
          image: `https://cdn.codnum.fr/watch4safe/assets/images/logout_${darkMode ? 'black' : 'white'}.svg`,
          icon: false,
          title: 'Me déconnecter',
          link: '/logout'
        }]} position={1} userAccount={{userID: authData.userID, name: `${authData.firstName} ${authData.lastName}`}}/>
      </div>
      <div className={NavBarStyle.version}>
        <span>v{process.env.REACT_APP_VERSION}</span>
      </div>
    </nav>
  );
};

export default NavBar;
