import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../provider/authProvider";
import {AppProvider} from "../provider/appProvider";
import {PopupProvider} from "../services/PopupContext";

export const ProtectedRoute = () => {
  const { user } = useAuth();

  if (!user) {
      return <Navigate to="/auth/login" />;
  }

  return (
    <AppProvider>
      <PopupProvider>
        <Outlet />
      </ PopupProvider>
    </AppProvider>
  );
};
