import React from "react";
import NotificationsDateElementStyle from '../assets/css/NotificationsDateElement.module.css';
import NotificationElement from "./NotificationElement";
import {useTheme} from "../provider/themeProvider";

const NotificationsDateElement = (props) => {
  const { date, notifications } = props;

  const { darkMode } = useTheme();

  return (
    <div className={NotificationsDateElementStyle.element}>
      <div className={NotificationsDateElementStyle.line}></div>

      <div className={NotificationsDateElementStyle.container}>
        <div className={NotificationsDateElementStyle.title}>
          <div className={NotificationsDateElementStyle.icon}>
            <img src={`https://cdn.codnum.fr/watch4safe/assets/images/calendar_${darkMode ? 'black' : 'white'}.svg`}
                 alt="" height="26" width="26"/>
          </div>
          <span className={NotificationsDateElementStyle.date}>{new Intl.DateTimeFormat('fr-FR', {
            weekday: 'long',
            day: 'numeric',
            month: 'long',
            timeZone: 'Europe/Paris' }).format(date)}</span>
        </div>
        <div className={NotificationsDateElementStyle.content}>
          {notifications.map((notification, index) => {
            return (
              <NotificationElement key={index} callback={notification.callback} readed={notification.read_at} type={notification.type} title={notification.title} description={notification.description} calledBy={notification.called_by} createdAt={new Date(notification.created_at)} thumbnail={notification.thumbnail}/>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default NotificationsDateElement;
