import React, { createContext, useContext, useState, useEffect } from 'react';
import PopupDown from "../components/PopupDown/PopupDown";

const PopupContext = createContext();

export function usePopup() {
  return useContext(PopupContext);
}

export const PopupProvider = ({ children }) => {
  const [popups, setPopups] = useState([]);

  const showPopup = (Component, props, canBeHidden = true) => {
    setPopups(prevPopups => [...prevPopups, { Component, props, canBeHidden }]);
  };

  const hidePopup = (index) => {
    setPopups(popups.filter((_, i) => i !== index));
  };

  const clearAll = (index) => {
    setPopups([]);
  };

  useEffect(() => {
  }, [popups]);

  return (
    <PopupContext.Provider value={{ showPopup, hidePopup, clearAll }}>
      {children}
      {popups.map((popup, index) => (
        <PopupDown key={index} index={index} component={popup.Component} props={popup.props} canBeHidden={popup.canBeHidden} />
      ))}
    </PopupContext.Provider>
  );
};
