import React, {useEffect, useState, useRef} from "react";

import ToastElementStyle from './ToastElement.module.css';
import { ReactComponent as CloseIcon } from '../../assets/images/close.svg';
import { ReactComponent as LoaderIcon } from '../../assets/images/loader.svg';
import {useNavigate} from "react-router-dom";

const ToastElement = (props) => {
  const { type, title, description, onDelete, content, created_at = Date.now(), time=10, callback='/' } = props;

  const progressBarRef = useRef();
  const closeRef = useRef();

  const durationInSeconds = time;

  const calculateProgress = (elapsedTime) => {
    return Math.max(0, 100 - (elapsedTime / durationInSeconds) * 100);
  };

  const [progress, setProgress] = useState(100);
  const [startTime, setStartTime] = useState(Date.now());
  const [loader, setLoader] = useState(true);
  const [timeElapsed, setTimeElapsed] = useState(0);

  const requestAnimationRef = useRef(null);
  const navigate = useNavigate();
  const deleteElement = () => {
    cancelAnimationFrame(requestAnimationRef.current);
    onDelete();
  }

  const handleCallCallback = (event) => {
    if (!closeRef.current.contains(event.target)) {
      navigate(callback, { replace: true });
      deleteElement();
    }
  }

  useEffect(() => {
    setStartTime(Date.now());

    const updateProgress = () => {
      const currentTime = Date.now();
      const elapsedTime = currentTime - startTime;
      const newProgress = calculateProgress(elapsedTime / 1000);
      setProgress(newProgress);


      setTimeElapsed(Number.parseInt((currentTime - created_at) / 1000));

      if (newProgress > 0) {
        requestAnimationRef.current = requestAnimationFrame(updateProgress);
      }
    };

    updateProgress();
  }, []);

  useEffect(() => {
    if (progress <= 0) {
      deleteElement();
    }
  }, [progress, onDelete]);

  useEffect(() => {
    if (progressBarRef.current) {
      progressBarRef.current.style.width = `${progress}%`;
    }
  }, [progress]);

  return (
    <div className={`${ToastElementStyle.toastElement} ${ToastElementStyle[`${type}`]}`} onClick={handleCallCallback}>
      <div className={ToastElementStyle.content}>
        <div className={ToastElementStyle.header}>
          <div className={ToastElementStyle.icon}>
            <img src={`https://cdn.codnum.fr/watch4safe/icons/toast/${type}.svg`} alt={type} height={36} width={36}/>
          </div>
          <div className={ToastElementStyle.message}>
            {title && <span className={ToastElementStyle.title}>{title}</span>}
            {description && <span className={ToastElementStyle.description}>{description}</span>}
          </div>
          <div ref={closeRef} className={ToastElementStyle.close} onClick={deleteElement}>
            <CloseIcon height={12} width={12}/>
          </div>
        </div>
        {
          content &&
          <>
            <div className={ToastElementStyle.data}>
              {loader &&
                <>
                  <LoaderIcon className={ToastElementStyle.dataLoader} height={40} width={40}></LoaderIcon>
                </>
              }
              <img className={ToastElementStyle.dataImage} src={content} alt="la pièce jointe"
                   onLoad={() => setLoader(false)}/>
            </div>
          </>
        }
        <div ref={progressBarRef} className={ToastElementStyle.loadingBar}></div>
        <div className={ToastElementStyle.timeElapsed}>
          <span>Il y a {timeElapsed}s</span>
        </div>
      </div>
    </div>
  );
};

export default ToastElement;
