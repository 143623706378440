import { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useAuth } from "../provider/authProvider";

const Logout = () => {
  const { setUser } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const handleLogout = async () => {
      await setUser(null);
      navigate("/auth/login", { replace: true });
    };

    handleLogout();
  }, [setUser, navigate]);

  return <>Logout Page</>;
};

export default Logout;