import React, { useState, useEffect, useRef } from "react";

import SelectorBase from './SelectorElement.module.css';

import {Link} from "react-router-dom";

import {useTheme} from "../../provider/themeProvider";
import api from "../../services/api";
import {useAuth} from "../../provider/authProvider";
import TokenService from "../../services/token.service";
import UserImageProfile from "../UserImageProfile";

const SelectorElement = (props) => {
  const { elementSelected, elements, position, userAccount = null } = props;

  const hasElements = elements && elements instanceof Array && elements.length > 0;

  const [opened, setOpened] = useState(false);

  const selectorRef = useRef(null);
  const { darkMode } = useTheme();
  const {setUser} = useAuth();

  const handleClickLink = async (element) => {
    if (!element.link && element.id) {
      try {
        const response = await api.post(`/client/systems/change/${element.id}`, {refreshToken: TokenService.getLocalRefreshToken()});

        await setUser(response.data);
        window.location.reload();

        setOpened(false);
      } catch (error) {
        console.log('error');
      }
    } else {
      setOpened(false);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (selectorRef.current && !selectorRef.current.contains(event.target)) {
        setOpened(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [selectorRef]);

  return (
    <div className={SelectorBase.selector} ref={selectorRef}>
      <div className={`${SelectorBase.selectorDefaultContainer} ${hasElements ? SelectorBase.pointerCursor : ''}`} onClick={() => (hasElements ? setOpened(!opened) : null)}>
        <div className={SelectorBase.iconContainer}>
          {!userAccount ?
            <>
              <img src={elementSelected.image} alt="" height="44" width="44" className={SelectorBase.systemIcon}/>
            </>
            :
            <>
              <UserImageProfile userID={userAccount.userID} name={userAccount.name} size={50}/>
            </>
          }
        </div>

        <div className={SelectorBase.descriptionContainer}>
          <span className={SelectorBase.descriptionTitle}>{elementSelected.title}</span>
          <span className={SelectorBase.descriptionSubTitle}>{elementSelected.subTitle}</span>
        </div>

        {
          hasElements &&
          <div className={SelectorBase.arrowContainer}>
            <img className={`${SelectorBase.arrow} ${opened ? SelectorBase.opened : ''}`} src={`https://cdn.codnum.fr/watch4safe/assets/images/arrow_${darkMode ? 'black' : 'white'}.svg`} alt="" height="12" width="12" />
          </div>
        }
      </div>
      <div className={`${SelectorBase.arrowFilledContainer} ${opened ? SelectorBase.display : ''}  ${position === 0 ? SelectorBase.toTopArrow : SelectorBase.toBottomArrow }`}>
        <img src={`https://cdn.codnum.fr/watch4safe/assets/images/arrow_filled_${darkMode ? 'black' : 'white'}.svg`} alt="" height="20" width="20" />
      </div>

      <div className={`${SelectorBase.selectorContainer} ${opened ? SelectorBase.display : ''} ${position === 0 ? SelectorBase.toTopContainer : SelectorBase.toBottomContainer }`}>
        <div className={SelectorBase.currentSelected}>
          {!userAccount ?
            <>
              <img className={SelectorBase.selectedImage} src={elementSelected.image} alt="" height="40" width="40"/>
            </>
            :
            <>
              <UserImageProfile userID={userAccount.userID} name={userAccount.name} size={40}/>
            </>
          }

          <div className={SelectorBase.selectedDescription}>
            <span className={SelectorBase.selectedTitle}>{elementSelected.title}</span>
            <span className={SelectorBase.selectedSubTitle}>{elementSelected.subTitle}</span>
          </div>
        </div>

        <div className={SelectorBase.separator}></div>

        <div className={SelectorBase.links}>
        {elements.map((element, index) => {
          const Icon = element.image;
            return (
              <Link key={index} to={element.link} className={SelectorBase.link} onClick={() => handleClickLink(element)}>
                {
                  element.icon ?
                  <Icon height="20" width="20" /> :
                  <img className={SelectorBase.elementImage} src={element.image} alt="" height="20" width="20" />
                }
                <span className={SelectorBase.elementTitle}>{element.title}</span>
              </Link>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default SelectorElement;
