import {useState} from 'react';
import { useNavigate, Link } from "react-router-dom";
import { useAuth } from "../provider/authProvider";

import BaseStyle from '../assets/css/Authentication.module.css';
import LoginStyle from '../assets/css/Login.module.css';

import Input from '../components/Form/Input.component'
import Button from '../components/Form/Button.component'

import {login} from '../services/authService'

const Login = () => {
  const navigate = useNavigate();

  const {setUser} = useAuth();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  const handleLogin = (e) => {
    e.preventDefault();
    setLoading(true);

    login(email, password).then(
      async (data) => {
        await setUser(data);
        setTimeout(() => {
          navigate("/", { replace: true });
        }, 500)
      },
      error => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setLoading(false);
        setMessage(resMessage);
      }
    );
  };

  return <>
    <form className={LoginStyle.signIn} onSubmit={handleLogin}>
      <Input
        type={'email'}
        name={'email'}
        required={true}
        label={'Adresse email'}
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />

      <Input
        type={'password'}
        name={'password'}
        required={true}
        label={'Mot de passe'}
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      {/* Adresse e-mail ou mot de passe incorrect. */}
      {message && <span className={BaseStyle.errorMessage}>{message}</span>}

      <div className={LoginStyle.lostPasswordContainer}>
        <Link className={LoginStyle.lostPassword} to='/authentication/reset-password-send'>Mot de passe oublié ?</Link>
      </div>

      <Button
        type={'submit'}
        name={'Connexion'}
        disabled={loading}
      />
    </form>
  </>;
};

export default Login;
