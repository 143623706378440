import React from "react";
import { useTheme } from '../../provider/themeProvider';
import NavigationBarStyle from './NavigationBar.module.css';
import NavigationElement from "./NavigationElement";
import NavigationElementStyle from "./NavigationElement.module.css";
import {usePopup} from "../../services/PopupContext";
import PopupDownMenu from "../PopupDown/PopupDownMenu";

const NavigationBar = (props) => {
  const { darkMode } = useTheme();
  const { showPopup } = usePopup();

  return (
    <div className={NavigationBarStyle.navigationBar}>
      <NavigationElement link={'/'} icon={'home'} title={'Accueil'} size={22} />
      <NavigationElement link={'/cameras'} icon={'camera'} title={'Caméras'} size={22} />
      <NavigationElement link={'/sequences'} icon={'folder'} title={'Séquences'} size={22} />

      <div
        className={NavigationElementStyle.navigationElement}
        onClick={(event) => showPopup(PopupDownMenu, { title: 'Test' })}
      >
        <img
          className={NavigationElementStyle.icon}
          src={`https://cdn.codnum.fr/watch4safe/assets/images/menu_${darkMode ? 'black' : 'white'}.svg`}
          alt={'Menu'}
          height={22}
          width={22}
        />
      </div>
    </div>
  );
};

export default NavigationBar;
