import React, {useState} from "react";
import PopupDownOldShareStyle from './PopupDownOldShare.module.css';
import PopupDownStyle from '../../assets/css/PopupDown.module.css';
import {usePopup} from "../../services/PopupContext";
import dayjs from "dayjs";

const PopupDownOldShare = (props) => {
  const { title, callbackDelete, callbackDeleteAndNew, oldShare, linkCopy } = props;
  const { hidePopup } = usePopup();

  const [buttonCopieState, setButtonCopieState] = useState({
    value: 'Copier le lien',
    className: 'btn-01',
    disabled: false
  });

  const handleClick = () => {
    navigator.clipboard.writeText(linkCopy).then(() => {
      setButtonCopieState({
        value: 'Lien copié !',
        className: 'btn-02',
      });
    }).catch(err => {
      console.error('Erreur lors de la copie : ', err);
    });
  };

  return (
    <div className={PopupDownStyle.container}>
      <span className={PopupDownStyle.title}>{title}</span>
      <div className={PopupDownStyle.content}>
        <div className={PopupDownOldShareStyle.container}>
          <div className={PopupDownOldShareStyle.oldShare}>
            <div className={PopupDownOldShareStyle.oldShareText}>
              <span>Expire le {dayjs(oldShare.expiration_date).format('DD MMMM YYYY [à] HH[h]mm')}</span>
              <span>Protégé par un mot de passe: {oldShare.password ? 'Oui' : 'Non'}</span>
            </div>
            <input
              value={buttonCopieState.value}
              type="button"
              className={buttonCopieState.className}
              disabled={buttonCopieState.disabled}
              onClick={handleClick}
            />
          </div>
          <div className={PopupDownOldShareStyle.buttons}>
            <input
              value={'Supprimer'}
              type="button"
              className={'btn-01'}
              disabled={false}
              onClick={() => {
                hidePopup(0);
                callbackDelete();
              }}
            />

            <input
              value={'Supprimer et nouveau'}
              type="button"
              className={'btn-01'}
              disabled={false}
              onClick={() => {
                hidePopup(0);
                callbackDeleteAndNew();
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopupDownOldShare;
