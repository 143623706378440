import React, {useState} from "react";
import { useTheme } from '../../provider/themeProvider';
import PopupDownShareStyle from './PopupDownShare.module.css';
import PopupDownStyle from '../../assets/css/PopupDown.module.css';
import {usePopup} from "../../services/PopupContext";
import {DesktopDateTimePicker} from "@mui/x-date-pickers/DesktopDateTimePicker";
import dayjs from "dayjs";
import {MobileDateTimePicker} from "@mui/x-date-pickers/MobileDateTimePicker";
import Input from "../Form/Input.component";
import {useDesktop} from "../../provider/DesktopContext";

const PopupDownShare = (props) => {
  const { title, callbackShare } = props;
  const { darkMode } = useTheme();
  const { hidePopup } = usePopup();
  const isDesktop = useDesktop();
  const [password, setPassword] = useState('');

  const [date, setDate] = useState(dayjs().add(7, 'day'));

  const handleChangePassword = (event) => {
    setPassword(event.target.value);
  };

  const handleKeyUpInput = (event) => {
    if (event.key === 'Enter') {
      hidePopup(0);
      callbackShare(date, password);
    }
  };

  const css = {
    '.MuiPickersInputBase-root': {
      backgroundColor: darkMode ? '#303030' : '#F3F3F3',
      border: 0,
      color: darkMode ? '#FFFFFF' : '#0A0908',
      padding: '0 6px',
    },
    'fieldset': {
      border: '0 !important',
    },
    '.MuiSvgIcon-root': {
      color: darkMode ? '#FFFFFF' : '#0A0908',
    },
    '.MuiPickersSectionList-root': {
      padding: '6px 0px',
    }
  }

  return (
    <div className={PopupDownStyle.container}>
      <span className={PopupDownStyle.title}>{title}</span>
      <div className={PopupDownStyle.content}>
        <div className={PopupDownShareStyle.container}>
          <div className={PopupDownShareStyle.dates}>
            <span className={PopupDownShareStyle.request}>Valide jusqu'au*</span>
            { isDesktop &&
              <DesktopDateTimePicker
                onChange={(newValue, context) => {
                  console.log(newValue);
                  if (context.validationError === null && newValue !== null) {
                    setDate(newValue);
                  } else {
                    setDate(null);
                  }
                }}
                required={true}
                className={PopupDownShareStyle.dateTimePicker}
                ampm={false}
                format="DD/MM/YYYY à HH:mm"
                minDateTime={dayjs(new Date())}
                maxDateTime={dayjs(new Date()).add(1, 'month')}
                timezone="Europe/Paris"
                defaultValue={date}
                timeSteps={{ hours: 1, minutes: 1}}
                skipDisabled
                sx={css}
                enableAccessibleFieldDOMStructure={true}
              />
            }

            { !isDesktop &&
              <MobileDateTimePicker
                onChange={(newValue, context) => {
                  if (context.validationError === null && newValue !== null) {
                    setDate(newValue);
                  }
                }}
                className={PopupDownShareStyle.dateTimePicker}
                ampm={false}
                format="DD/MM/YYYY à HH:mm"
                minDateTime={dayjs(new Date())}
                maxDateTime={dayjs(new Date()).add(1, 'month')}
                timezone="Europe/Paris"
                defaultValue={date}
                timeSteps={{ hours: 1, minutes: 1}}
                skipDisabled
                sx={css}
                enableAccessibleFieldDOMStructure={true}
              />
            }
          </div>
          <div className={PopupDownShareStyle.inputs}>
            <Input
              type={'password'}
              name={'password'}
              required={false}
              label={'Mot de passe'}
              value={password}
              onChange={handleChangePassword}
              onKeyUp={handleKeyUpInput}
            />
          </div>
          <div className={PopupDownShareStyle.buttons}>
            <input
              value={'Annuler'}
              type="button"
              className={'btn-02'}
              disabled={false}
              onClick={() => {
                hidePopup(0);
              }}
            />

            <input
              value={'Partager'}
              type="button"
              className={'btn-01'}
              disabled={date === null}
              onClick={() => {
                hidePopup(0);
                callbackShare(date, password);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopupDownShare;
