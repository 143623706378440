import React, {useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import NotificationElementStyle from '../assets/css/NotificationElement.module.css';
import api from "../services/api";
import dayjs from "dayjs";
import {useDesktop} from "../provider/DesktopContext";

const NotificationElement = (props) => {
  const { type, title, description, callback, calledBy, readed, createdAt, thumbnail, fullDate = false } = props;

  const [thumbnailLink, setThumbnailLink] = useState(null);
  const navigate = useNavigate();

  const isDesktop = useDesktop();

  const handleCallCallback = (event) => {
    navigate(callback, { replace: true });
  }

  useEffect(() => {
    const fetchThumbnail = async () => {
      try {
        if (thumbnail) {
          const response = await api.get(`pictures${thumbnail}`);
          setThumbnailLink(response.data.link);
        }
      } catch (error) {
        console.log((error.response && error.response.data) || error.message || error.toString());
      }
    };

    fetchThumbnail();
  }, []);

  return (
    <div className={NotificationElementStyle.element} onClick={handleCallCallback}>
      {isDesktop &&
        <div className={NotificationElementStyle.icon}>
          <img src={`https://cdn.codnum.fr/watch4safe/icons/toast/${type}.svg`} alt={type} height={24} width={24}/>
        </div>
      }
      <div className={NotificationElementStyle.content}>
        <div className={NotificationElementStyle.left}>
          {!isDesktop &&
            <div className={NotificationElementStyle.iconMobile}>
              <img src={`https://cdn.codnum.fr/watch4safe/icons/toast/${type}.svg`} alt={type} height={24} width={24}/>
            </div>
          }
          <div className={NotificationElementStyle.text}>
            <span className={NotificationElementStyle.title}>{title}</span>
            <span
              className={NotificationElementStyle.description}>{description}{(description && calledBy) && '・'}{calledBy}</span>
            <span
              className={NotificationElementStyle.description_02}>{dayjs(createdAt).format(fullDate ? '[Le] DD MMMM YYYY [à] HH[h]mm [et] ss[s]' : '[À] HH[h]mm [et] ss[s]')}</span>
          </div>
        </div>

        <div className={NotificationElementStyle.right}>
          {thumbnailLink == null &&
            <>
              {/*<LoaderIcon className={'loader'} height={40} width={40}></LoaderIcon>*/}
            </>
          }
          {thumbnailLink != null &&
            <>
              <img loading="lazy" className={NotificationElementStyle.thumbnail} src={thumbnailLink}
                   alt="Moment de la capture"
                   onError={(e) => { e.target.style.display = 'none'; }}/>
            </>
          }

        </div>
      </div>
      {!readed &&
        <div className={NotificationElementStyle.notReaded}></div>
      }
    </div>
  );
};

export default NotificationElement;
